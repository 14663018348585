import { EMAIL_KEY, MSISDN_KEY } from 'constants/form';
import { INPUT_MODES, RESULTS } from 'constants/const';

export default {
  FOOTER: {
    HELPDESK: 'HelpDesk@beeline.ru',
  },
  INITIAL_PAGE: {
    TITLE: 'Для сброса PIN-кода введите свои корпоративные номер телефона и&nbsp;e-mail',
    SUBMIT_BUTTON_TEXT: 'Продолжить',
    FORM: {
      [MSISDN_KEY]: {
        id: MSISDN_KEY,
        name: MSISDN_KEY,
        label: 'Номер телефона',
        placeholder: 'Номер телефона (только билайн)',
        inputMode: INPUT_MODES.NUMERIC,
      },
      [EMAIL_KEY]: {
        id: EMAIL_KEY,
        name: EMAIL_KEY,
        label: 'E-mail',
        placeholder: 'Корпоративный E-mail',
        inputMode: INPUT_MODES.EMAIL,
      },
    },
  },
  RESULT: {
    [RESULTS.EMAIL_SUCCESS]: {
      ICON: 'sms_ok',
      TITLE: 'Мы отправили письмо на адрес {{email}}',
      DESCRIPTION: 'Перейдите по ссылке в письме, чтобы установить новый PIN-код для номера {{msisdn}}',
    },
    [RESULTS.PIN_SUCCESS]: {
      ICON: 'phone_success',
      TITLE: 'Новый PIN-код успешно установлен',
      DESCRIPTION: 'Данную страницу можно закрыть',
    },
    [RESULTS.PUSH]: {
      ICON: 'push_message',
      TITLE: 'Отлично! Теперь проверьте экран вашего телефона и дождитесь PUSH-уведомления',
      DESCRIPTION: 'Мы отправили его на номер {{msisdn}}. Следуйте указаниям на экране для установки нового PIN-кода',
    },
    [RESULTS.ERROR]: {
      ICON: 'error',
      TITLE: 'Что-то пошло не так',
      DESCRIPTION: 'Повторите попытку позже',
      BUTTON_TEXT: 'Вернуться назад',
    },
    [RESULTS.CORPORATE_ERROR]: {
      ICON: 'sms_error',
      TITLE: 'Указанный вами адрес e-mail не&nbsp;является&nbsp;корпоративным',
      DESCRIPTION: 'Вернитесь назад и укажите адрес вашей рабочей электронной почты',
      BUTTON_TEXT: 'Вернуться назад',
    },
    [RESULTS.SIM_ERROR]: {
      ICON: 'sim_error',
      TITLE: 'К сожалению, ваша версия SIM-карты не&nbsp;поддерживает установку PIN-кода',
      DESCRIPTION: 'Пожалуйста, замените SIM-карту на новую в салоне билайн. Номер телефона менять не придётся',
    },
    [RESULTS.EXPIRED]: {
      ICON: 'link_error',
      TITLE: 'Ссылка не верна или устарела',
      DESCRIPTION: 'Попробуйте перейти по ней повторно или&nbsp;выполните запрос новой ссылки',
      BUTTON_TEXT: 'Запросить новую ссылку',
    },
  },
};
