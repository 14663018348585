import { isDesktopVersion, isMobileVersion, isTabletVersion } from 'constants/breakpoints';

import { useAdaptivity } from 'context';

export const withBreakpoints = (WrappedComponent) => {
  const ConnectedConsumer = (props) => {
    const [breakpoint] = useAdaptivity();

    return (
      <WrappedComponent
        {...props}
        isMobile={isMobileVersion(breakpoint)}
        isTablet={isTabletVersion(breakpoint)}
        isDesktop={isDesktopVersion(breakpoint)}
      />
    );
  };

  /**
   * Override Connector's name to withContext(%Component%)
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ConnectedConsumer.displayName = `withBreakpoints(${WrappedComponentName})`;
  }

  return ConnectedConsumer;
};
