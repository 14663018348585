import PropTypes from 'prop-types';

import CONTENT from 'constants/content';
import { RESULTS } from 'constants/const';

import { withBreakpoints } from 'enhancers';

import { ResultLayout } from 'components';
import { Text, Title } from 'ui';

const content = CONTENT.RESULT[RESULTS.PIN_SUCCESS];

const PinSuccess = ({ isMobile }) => (
  <ResultLayout icon={content.ICON}>
    <Title isCentering={!isMobile}>
      {content.TITLE}
    </Title>

    {content.DESCRIPTION && (
      <Text className="mart-xs" isCentering={!isMobile} size="me">
        {content.DESCRIPTION}
      </Text>
    )}
  </ResultLayout>
);

PinSuccess.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoints(PinSuccess);
