import classNames from 'classnames/bind';

import { Preloader } from '../Preloader';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Suspense = () => (
  <div className={cx('component')}>
    <Preloader className={cx('preloader')} />
  </div>
);
