import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { resetPinStatusRequest } from 'api/requests';

import routes from 'constants/routes';
import { MSISDN_KEY } from 'constants/form';
import { POLLING_CONFIG, STATUSES } from 'constants/const';

let timeoutFN;

export const usePolling = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [query] = useSearchParams();
  const msisdn = query.get(MSISDN_KEY);

  const { run: getStatus, cancel } = useRequest(resetPinStatusRequest, {
    manual: true,
    pollingInterval: POLLING_CONFIG.interval,
    onSuccess: ({ data, status }) => {
      if (status === 202) return;

      cancel();
      clearTimeout(timeoutFN);

      if (data.status === STATUSES.SUCCESS) {
        navigate(routes.pinSuccess);
        return;
      }

      if (data.status === STATUSES.SIM) {
        navigate(routes.simError);
      }
    },
    onError: () => {
      cancel();
      clearTimeout(timeoutFN);

      navigate(routes.error);
    },
  });

  const handlePolling = () => {
    getStatus(code);
    timeoutFN = setTimeout(() => { cancel(); }, POLLING_CONFIG.timeout);
  };

  return { msisdn, handlePolling };
};
