import { createContext, useContext } from 'react';
import { formatPhone } from 'utils/format-string';

export const GlobalContext = createContext({});

export const useAdaptivity = () => {
  const { breakpoint, setBreakpoint } = useContext(GlobalContext);

  return [breakpoint, setBreakpoint];
};

export const useMsisdn = () => {
  const { msisdn, setMsisdn } = useContext(GlobalContext);

  const handleSetMsisdn = (ctn) => { setMsisdn(formatPhone(ctn)); };

  return [msisdn, handleSetMsisdn];
};

export const useEmail = () => {
  const { email, setEmail } = useContext(GlobalContext);

  return [email, setEmail];
};
