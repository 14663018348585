import { Text } from 'ui';

export const withError = (WrappedComponent) => ({ active, error, ...restProps }) => (
  <>
    <WrappedComponent error={active ? undefined : error} {...restProps} />

    {!active && error && (
      <Text size="s" className="mart-xxs" isError>
        {error}
      </Text>
    )}
  </>
);
