import classNames from 'classnames/bind';

import { childrenPropTypes } from 'utils/prop-types';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Layout = ({ children }) => (
  <div className={cx('layout')}>
    {children}
  </div>
);

Layout.propTypes = {
  children: childrenPropTypes,
};
