import { useMemo } from 'react';
import PropTypes from 'prop-types';

export const Image = ({ name, alt = '', ...restProps }) => {
  const src = useMemo(() => `https://static.beeline.ru/upload/MobileID/images/aggregator/${name}.webp`, [name]);

  return (
    <img src={src} alt={alt} {...restProps} />
  );
};

Image.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string,
  restProps: PropTypes.object,
};
