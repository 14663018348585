// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".atK8mG {\n  position: absolute;\n  top: 6px;\n  left: 16px;\n\n  opacity: 0;\n}\n\n.UngYDD:focus,\n.UngYDD._kUD1X {\n  padding: 22px 16px 10px;\n}\n\n.UngYDD:focus + .atK8mG,\n.UngYDD._kUD1X + .atK8mG {\n  opacity: 0.4;\n}\n", "",{"version":3,"sources":["webpack://./src/forms/enhancers/withLabel/styles.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;;EAEV,UAAU;AACZ;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;;EAEE,YAAY;AACd","sourcesContent":[".label {\n  position: absolute;\n  top: 6px;\n  left: 16px;\n\n  opacity: 0;\n}\n\n.input:focus,\n.input.withValue {\n  padding: 22px 16px 10px;\n}\n\n.input:focus + .label,\n.input.withValue + .label {\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "atK8mG",
	"input": "UngYDD",
	"withValue": "_kUD1X"
};
export default ___CSS_LOADER_EXPORT___;
