import {
  composeEmailValidator,
  composePhoneValidator,
  composeRequiredValidator,
  composeXSSValidator,
} from 'utils/validators';

export const MSISDN_KEY = 'msisdn';
export const EMAIL_KEY = 'email';

export const FORM_VALIDATION = {
  required: 'Поле должно быть заполнено',
  xss: 'Введены некорректные символы',
  [MSISDN_KEY]: 'Укажите номер телефона',
  [EMAIL_KEY]: 'Укажите e-mail',
};

export const validators = () => ({
  [MSISDN_KEY]: [
    composeRequiredValidator(FORM_VALIDATION.required),
    composePhoneValidator(FORM_VALIDATION[MSISDN_KEY]),
    composeXSSValidator(FORM_VALIDATION.xss),
  ],
  [EMAIL_KEY]: [
    composeRequiredValidator(FORM_VALIDATION.required),
    composeEmailValidator(FORM_VALIDATION[EMAIL_KEY]),
    composeXSSValidator(FORM_VALIDATION.xss),
  ],
});
