import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes } from 'utils/prop-types';

import { Image } from '../Image';
import { Preloader } from '../Preloader';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ResultLayout = ({ icon, children, isLoading }) => (
  <div className={cx('component')}>
    {isLoading ? <Preloader /> : (
      <>
        <Image name={icon} width="120px" height="120px" className="marb-s" />

        {children}
      </>
    )}
  </div>
);

ResultLayout.propTypes = {
  isLoading: PropTypes.bool,
  icon: PropTypes.string,
  children: childrenPropTypes,
};
