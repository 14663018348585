export const IOS = 'ios';
export const ANDROID = 'android';
export const WINDOWS = 'windows';
export const UNDEFINED = 'undefined';

export const DEV_API = process.env.DEV_API || '';
export const IS_PROD_ENV = process.env.NODE_ENV === 'production';
export const IS_DEV_ENV = process.env.NODE_ENV === 'development';

export const KEYBOARD_BACKSPACE_KEYCODE = 8;

export const BREAKPOINT_768 = 768;
export const BREAKPOINT_1004 = 1004;
export const BREAKPOINT_1280 = 1280;

/**
 * Lower value of breakpoint is used to enum interval,
 * last breakpoint - infinity, is used for conveniency of comparison
 */
export const breakpoints = [Number.MAX_VALUE, BREAKPOINT_1280, BREAKPOINT_1004, BREAKPOINT_768];

export const DEFAULT_ERROR = 'Что-то пошло не так. Повторите попытку позже.';

export const INPUT_MODES = {
  NONE: 'none',
  TEXT: 'text',
  DECIMAL: 'decimal',
  NUMERIC: 'numeric',
  TEL: 'tel',
  EMAIL: 'email',
  URL: 'url',
};

export const RESULTS = {
  PUSH: 'push',
  EMAIL_SUCCESS: 'emailSuccess',
  PIN_SUCCESS: 'pinSuccess',
  ERROR: 'error',
  CORPORATE_ERROR: 'corporate',
  SIM_ERROR: 'sim',
  EXPIRED: 'expired',
};

export const STATUSES = {
  SUCCESS: 'Success',
  SIM: 'PIN_not_supported',
  IN_PROGRESS: 'processing',
};

export const POLLING_CONFIG = {
  timeout: 70000,
  interval: 2000,
};
