import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import { reactivateRequest } from 'api/requests';

import routes from 'constants/routes';

import { composeCorporateEmailValidator } from 'utils/validators';

export const useReactivate = () => {
  const navigate = useNavigate();

  const { run: handleRequest } = useRequest(reactivateRequest, {
    manual: true,
    onSuccess: () => { navigate(routes.emailSuccess); },
    onError: () => { navigate(routes.error);},
  });

  const handleReactivate = (values) => {
    if (composeCorporateEmailValidator(values.email)) {
      handleRequest(values);
      return;
    }

    navigate(routes.corporateError);
  };

  return { handleReactivate };
};
