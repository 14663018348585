import { useCallback } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { ANDROID } from 'constants/const';

import compose from 'utils/compose';
import { classNamePropTypes } from 'utils/prop-types';

import { usePlatform } from 'hooks';

import { BasicInput } from 'ui';

import { withError } from '../../enhancers/withError';
import { withLabel } from '../../enhancers/withLabel';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const InputComponent = compose(withError, withLabel)(BasicInput);

export const Input = ({
  value,
  maxLength,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  className,
  ...DOMProps
}) => {
  const platform = usePlatform();

  const handleBlur = useCallback((e) => {
    onBlur?.(value, e);
  }, [value, onBlur]);

  const handleFocus = useCallback((e) => {
    onFocus?.(value, e);
  }, [value, onFocus]);

  const handleChange = useCallback((e) => {
    // To fix Android bug https://issuetracker.google.com/issues/36951629
    if (platform === ANDROID && maxLength && e.target.value.length > +maxLength) return;

    onChange?.(e.target.value, e);
  }, [onChange, maxLength]);

  const handleKeyDown = useCallback((event) => {
    onKeyDown?.(event);
  }, [onKeyDown]);

  return (
    <label className={cx('component')}>
      <InputComponent
        value={value === '+7 ' ? '' : value}
        className={cx('input', className)}
        {...DOMProps}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </label>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: classNamePropTypes,
  DOMProps: PropTypes.object,
};
