import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import CONTENT from 'constants/content';
import { RESULTS } from 'constants/const';

import { useEmail, useMsisdn } from 'context';

import { makeUnbreakable, templateParser } from 'utils/format-string';

import { withBreakpoints } from 'enhancers';

import { ResultLayout } from 'components';
import { Text, Title } from 'ui';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const content = CONTENT.RESULT[RESULTS.EMAIL_SUCCESS];

const EmailSuccess = ({ isMobile }) => {
  const [msisdn] = useMsisdn();
  const [email] = useEmail();

  return (
    <ResultLayout icon={content.ICON}>
      <Title isCentering={!isMobile}>
        {templateParser(content.TITLE, { email })}
      </Title>

      {content.DESCRIPTION && (
        <Text
          className={cx('text', 'mart-xs')}
          isCentering={!isMobile}
          size="me"
        >
          {templateParser(content.DESCRIPTION, { msisdn: makeUnbreakable(msisdn) })}
        </Text>
      )}
    </ResultLayout>
  );
};

EmailSuccess.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoints(EmailSuccess);
