import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Button, Title } from 'ui';

import CONTENT from 'constants/content';
import { validators, MSISDN_KEY, EMAIL_KEY } from 'constants/form';

import { useEmail, useMsisdn } from 'context';

import { useReactivate } from 'hooks';

import { withBreakpoints } from 'enhancers';

import { FinalForm } from 'forms/FinalForm';
import { FFInput, FFPhoneInput } from 'forms';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const { INITIAL_PAGE } = CONTENT;

const Page = ({ isMobile }) => {
  const { handleReactivate } = useReactivate();
  const [, setMsisdn] = useMsisdn();
  const [, setEmail] = useEmail();

  const handleSubmit = (values) => {
    setMsisdn(values[MSISDN_KEY]);
    setEmail(values[EMAIL_KEY]);
    handleReactivate(values);
  };

  return (
    <div className={cx('component')}>
      <Title className={cx('title')} isCentering={!isMobile}>
        {INITIAL_PAGE.TITLE}
      </Title>

      <FinalForm
        className={cx('form')}
        values={{}}
        onSubmit={handleSubmit}
        getValidators={validators}
      >
        {({ invalid, submitting }) => (
          <>
            <FFPhoneInput {...INITIAL_PAGE.FORM[MSISDN_KEY]} />

            <FFInput {...INITIAL_PAGE.FORM[EMAIL_KEY]} />

            <Button type="submit" className={cx('button')} disabled={invalid || submitting}>
              {INITIAL_PAGE.SUBMIT_BUTTON_TEXT}
            </Button>
          </>
        )}
      </FinalForm>
    </div>
  );
};

Page.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoints(Page);
