import classNames from 'classnames/bind';

import CONTENT from 'constants/content';

import LogoSvg from 'media/mobid.svg';

import { Text, Wrapper } from 'ui';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const { HELPDESK } = CONTENT.FOOTER;

export const Footer = () => (
  <Wrapper className={cx('wrapper')}>
    <footer className={cx('component')}>
      <LogoSvg className={cx('logo')} />

      <a className={cx('link')} href={`mailto:${HELPDESK}`} referrerPolicy="no-referrer">
        <Text isInverse>
          {HELPDESK}
        </Text>
      </a>
    </footer>
  </Wrapper>
);
