import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import routes from 'constants/routes';
import CONTENT from 'constants/content';
import { RESULTS } from 'constants/const';

import compose from 'utils/compose';

import { withAdaptivity, withGlobalContext } from 'enhancers';

import { PageLayout, PageSuspense } from 'components';

import ErrorPage from 'pages/Error';
import PinSuccessPage from 'pages/Success/Pin';
import EmailSuccessPage from 'pages/Success/Email';

const InitialPage = lazy(() => import(/* webpackChunkName: "InitialPage" */ '../pages/InitialPage'));
const PushPage = lazy(() => import(/* webpackChunkName: "Push" */ '../pages/Push'));

const AppComponent = () => (
  <Suspense fallback={<PageSuspense />}>
    <BrowserRouter>
      <PageLayout>
        <Routes>
          <Route path={routes.initial} element={<InitialPage />} />
          <Route path={routes.push} element={<PushPage />} />

          {/* SUCCESS */}
          <Route path={routes.emailSuccess} element={<EmailSuccessPage />} />
          <Route path={routes.pinSuccess} element={<PinSuccessPage />} />

          {/* ERRORS */}
          <Route
            path={routes.error}
            element={<ErrorPage content={CONTENT.RESULT[RESULTS.ERROR]} />}
          />
          <Route
            path={routes.corporateError}
            element={<ErrorPage content={CONTENT.RESULT[RESULTS.CORPORATE_ERROR]} />}
          />
          <Route
            path={routes.simError}
            element={<ErrorPage content={CONTENT.RESULT[RESULTS.SIM_ERROR]} />}
          />
          <Route
            path={routes.expired}
            element={<ErrorPage content={CONTENT.RESULT[RESULTS.EXPIRED]} />}
          />

          <Route path="*" element={<Navigate to={routes.initial} />} />
        </Routes>
      </PageLayout>
    </BrowserRouter>
  </Suspense>
);

export const App = compose(
  withGlobalContext,
  withAdaptivity,
)(AppComponent);
