import classNames from 'classnames/bind';

import BeelineLogo from 'media/beeline.png';

import { Wrapper } from 'ui';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Header = () => (
  <Wrapper className={cx('wrapper')}>
    <header className={cx('component')}>
      <div className={cx('logo')}>
        <img src={BeelineLogo} alt="Логотип билайн" />
      </div>
    </header>
  </Wrapper>
);
