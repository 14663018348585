import classNames from 'classnames/bind';

import { childrenPropTypes } from 'utils/prop-types';

import { Layout } from '../Layout';
import { Header } from '../Header';
import { Footer } from '../Footer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PageLayout = ({ children }) => (
  <Layout>
    <Header />

    <div className={cx('content')}>
      {children}
    </div>

    <Footer />
  </Layout>
);

PageLayout.propTypes = {
  children: childrenPropTypes,
};
