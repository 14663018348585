import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';

import { resetPinRequest } from 'api/requests';

import routes from 'constants/routes';

export const useReset = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { code } = useParams();

  const { run: resetPin } = useRequest(resetPinRequest, {
    manual: true,
    onSuccess: () => {
      setLoading(false);
      onSuccess?.();
    },
    onError: ({ response }) => {
      if (response.status === 400 || response.status === 404) {
        navigate(routes.expired);
        return;
      }

      navigate(routes.error);
    },
  });

  const handleReset = () => resetPin(code);

  useEffect(() => { handleReset(); }, []);

  return { isLoading };
};
