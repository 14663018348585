import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes, classNamePropTypes } from 'utils/prop-types';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Text = ({
  size = 'regular',
  isError,
  children,
  isInline,
  isInverse,
  className,
  isBold500,
  isBold700,
  isSecondary,
  isCentering,
  isPlaceholder,
  ...props
}) => {
  const Tag = isInline ? 'span' : 'div';

  return (
    <Tag
      // classnames
      className={cx('component', className, size, {
        error: isError,
        inverse: isInverse,
        bold500: isBold500,
        bold700: isBold700,
        center: isCentering,
        secondary: isSecondary,
        placeholder: isPlaceholder,
      })}
      // check for need to use dangerouslySetInnerHTML
      {...(typeof children !== 'string'
        ? { children }
        : { dangerouslySetInnerHTML: { __html: children } }
      )
      }
      // other html attributes
      {...props}
    />
  );
};

Text.propTypes = {
  size: PropTypes.oneOfType(['s', 'regular', 'm', 'me', 'l']),
  isError: PropTypes.bool,
  isInline: PropTypes.bool,
  isInverse: PropTypes.bool,
  isBold500: PropTypes.bool,
  isBold700: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isCentering: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  props: PropTypes.object,
  children: childrenPropTypes,
  className: classNamePropTypes,
};
