import { MaskedInput } from './MaskedInput';

const unmaskValue = (maskedValue = '', prevMaskedValue) => {
  let value = maskedValue.replace(/(^\s*\+7|\D)/g, '');

  /** in special cases we have to do more complicated cleanup actions */
  if (maskedValue.indexOf('+') !== -1 && maskedValue.slice(0, 2) !== '+7') {
    // remove first 7 from the number if something got inserted in between
    value = value.replace(/7/, '');
  } else if (value.length > 10) {
    if (
      /** if field was empty before */
      !prevMaskedValue
      /** entire phone is replaced starting with 8 or somebody edits text before input */
      || maskedValue[0] !== '+' && value.length === 11
      /** user pasted new value into field when the field had +7 ( in it */
      || prevMaskedValue === '+7 '
    ) {
      value = value.slice(value.length - 10);
    } else if (
      /** if user pasted 8(xxx)xxx-xx-xx number into start of the field */
      '78'.indexOf(maskedValue[0]) !== -1 && value.length === 22 ||
      /** user pasted new value into field that had `+7 ` */
      maskedValue.slice(3, 5) === '+7'
    ) {
      // take first number, without leading 8
      value = value.slice(1);
    }
  }
  return value.slice(0, 10);
};

export const PhoneInput = (props) => (
  <MaskedInput
    {...props}
    mask="+7 ___ ___-__-__"
    unmaskValue={unmaskValue}
    inputMode="tel"
  />
);
