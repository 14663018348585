export const BREAKPOINT_0 = 0;
export const BREAKPOINT_400 = 400;
export const BREAKPOINT_502 = 502;
export const BREAKPOINT_640 = 640;
export const BREAKPOINT_768 = 768;
export const BREAKPOINT_890 = 890;
export const BREAKPOINT_1004 = 1004;
export const BREAKPOINT_1280 = 1280;
export const BREAKPOINT_1400 = 1400;

export const breakpoints = [
  Number.MAX_VALUE,
  BREAKPOINT_1400,
  BREAKPOINT_1280,
  BREAKPOINT_1004,
  BREAKPOINT_890,
  BREAKPOINT_768,
  BREAKPOINT_640,
  BREAKPOINT_502,
  BREAKPOINT_400,
  BREAKPOINT_0,
];

/**
 * Selectors for breakpoints, top resolution is INCLUDED in interval
 */
export const isScreenBelow768 = breakpoint => breakpoint < BREAKPOINT_768;
export const isScreenBelow1280 = breakpoint => breakpoint < BREAKPOINT_1280;

export const isMobileVersion = breakpoint => isScreenBelow768(breakpoint);
export const isTabletVersion = breakpoint => !isScreenBelow768(breakpoint) && isScreenBelow1280(breakpoint);
export const isDesktopVersion = breakpoint => !isScreenBelow1280(breakpoint);
