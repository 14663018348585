import classNames from 'classnames/bind';

import { childrenPropTypes, classNamePropTypes } from 'utils/prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Wrapper = ({ className, children }) => (
  <div className={cx('component', className)}>
    <div className={cx('content')}>
      {children}
    </div>
  </div>
);

Wrapper.propTypes = {
  className: classNamePropTypes,
  children: childrenPropTypes,
};
