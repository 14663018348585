// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".soTS_i {\n  position: relative;\n\n  min-height: 100vh;\n}\n\n.j4PqcN {\n  position: absolute;\n  top: calc(50% - 24px);\n  left: calc(50% - 24px);\n\n  -webkit-transform: translate(-50%, -50%);\n\n          transform: translate(-50%, -50%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Suspense/styles.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;;EAEtB,wCAAgC;;UAAhC,gCAAgC;AAClC","sourcesContent":[".component {\n  position: relative;\n\n  min-height: 100vh;\n}\n\n.preloader {\n  position: absolute;\n  top: calc(50% - 24px);\n  left: calc(50% - 24px);\n\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "soTS_i",
	"preloader": "j4PqcN"
};
export default ___CSS_LOADER_EXPORT___;
