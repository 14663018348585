import React, { useEffect, useRef, useState } from 'react';

import { breakpoints } from 'constants/breakpoints';

import { useAdaptivity } from 'context';

import { getWindowWidth, simulateResize } from './helpers';

/**
 * Listens resize to determine resolution, and pushes current breakpoint value into context
 */

export const withAdaptivity = (WrappedComponent) => (props) => {
  const mounted = useRef(false);
  const [isReady, setReady] = useState(false);
  const [breakpoint, setBreakpoint] = useAdaptivity();

  const updateBreakpoint = () => {
    const windowWidth = getWindowWidth();

    /** find closest breakpoint that we are surpassed in width */
    const newBreakpoint = breakpoints.find(bp => bp <= windowWidth);

    /** only call dispatcher if value changed */
    if (breakpoint !== newBreakpoint) {
      setBreakpoint(newBreakpoint);
    }

    if (!isReady) {
      setReady(true);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      window.addEventListener('resize', updateBreakpoint);
      updateBreakpoint();
      mounted.current = true;
    } else {
      simulateResize();
    }
  });

  return isReady ? <WrappedComponent {...props} /> : null;
};
