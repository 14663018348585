import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import routes from 'constants/routes';

import { withBreakpoints } from 'enhancers';

import { ResultLayout } from 'components';
import { Button, Text, Title } from 'ui';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Error = ({ content, isMobile }) => {
  const navigate = useNavigate();

  const handleClick = () => { navigate(routes.initial); };

  return (
    <ResultLayout icon={content.ICON}>
      <Title isCentering={!isMobile}>
        {content.TITLE}
      </Title>

      {content.DESCRIPTION && (
        <Text className={cx('mart-s', 'text')} isCentering={!isMobile} size="me">
          {content.DESCRIPTION}
        </Text>
      )}

      {content.BUTTON_TEXT && (
        <Button className={cx('mart-r', 'button')} onClick={handleClick}>
          {content.BUTTON_TEXT}
        </Button>
      )}
    </ResultLayout>
  );
};

Error.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoints(Error);
