import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes, classNamePropTypes } from 'utils/prop-types';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Title = ({
  size,
  level,
  children,
  className,
  isCentering,
  ...props
}) => {
  const Tag = level ? `h${level}` : 'h2';

  return (
    <Tag
      className={cx('component', className, size, { center: isCentering })}
      {...(typeof children !== 'string'
        ? { children }
        : { dangerouslySetInnerHTML: { __html: children } }
      )
      }
      {...props}
    />
  );
};

Title.defaultProps = {
  size: 'xl',
  level: 2,
};

Title.propTypes = {
  size: PropTypes.oneOfType(['s', 'regular', 'm', 'l', 'xl']),
  level: PropTypes.number,
  isCentering: PropTypes.bool,
  props: PropTypes.object,
  children: childrenPropTypes,
  className: classNamePropTypes,
};
