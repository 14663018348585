import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { classNamePropTypes, refPropTypes } from 'utils/prop-types';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const BasicInput = ({ forwardedRef, error, className, value = '', ...DOMProps }) => (
  <input
    type="text"
    autoComplete="on"
    ref={forwardedRef}
    data-invalid={!!error}
    className={cx('input', className)}
    value={value}
    {...DOMProps}
  />
);

BasicInput.defaultProps = { inputMode: 'text' };

BasicInput.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  inputMode: PropTypes.string,
  forwardedRef: refPropTypes,
  className: classNamePropTypes,
  DOMProps: PropTypes.object,
};
