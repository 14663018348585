import axios from 'axios';
import { DEV_API, IS_DEV_ENV } from 'constants/const';

/**
 * A base request with common params predefined
 * fully customizable, baseRequest is used to prevent boilerplate code generation
 * @returns {AxiosPromise}
 */

export const axiosBaseRequest = ({
  method = 'GET',
  url,
  responseType = 'json',
  withCredentials = true,
  timeout = 60000,
  ...options
}) => {
  const source = axios.CancelToken.source();

  if (timeout) {
    setTimeout(() => {
      source.cancel();
    }, timeout);
  }

  return axios({
    method,
    url: IS_DEV_ENV ? `${DEV_API}${url}` : url,
    responseType,
    withCredentials,
    ...options,
    cancelToken: source.token,
  });
};
