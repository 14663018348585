export default {
  initial: '/',
  emailSuccess: '/success/email',
  pinSuccess: '/success/pin',
  error: '/error',
  push: '/resetpin/:code',
  corporateError: '/error/corporate',
  simError: '/error/sim',
  expired: '/error/expired',
};
