import { useState } from 'react';

import { BREAKPOINT_1004 } from 'constants/const';

import { GlobalContext } from '../context';

export const withGlobalContext = (WrappedComponent) => {
  const Component = (props) => {
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [msisdn, setMsisdn] = useState(null);
    const [email, setEmail] = useState(null);
    const [breakpoint, setBreakpoint] = useState(BREAKPOINT_1004);

    const value = {
      isError,
      setError,
      isLoading,
      setLoading,
      msisdn,
      setMsisdn,
      email,
      setEmail,
      breakpoint,
      setBreakpoint,
    };

    return (
      <GlobalContext.Provider value={value}>
        <WrappedComponent {...props} />
      </GlobalContext.Provider>
    );
  };

  /**
   * Override Connector's name to withGlobalContext(%Component%)
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Component.displayName = `withGlobalContext(${WrappedComponentName})`;
  }
  return Component;
};
