import PropTypes from 'prop-types';

import CONTENT from 'constants/content';
import { RESULTS } from 'constants/const';

import { formatPhone, makeUnbreakable, templateParser } from 'utils/format-string';

import { withBreakpoints } from 'enhancers';

import { ResultLayout } from 'components';
import { Text, Title } from 'ui';

import { useReset } from './useReset';
import { usePolling } from './usePolling';

const content = CONTENT.RESULT[RESULTS.PUSH];

const Push = ({ isMobile }) => {
  const { msisdn, handlePolling: onSuccess } = usePolling();
  const { isLoading } = useReset({ onSuccess });

  return (
    <ResultLayout icon={content.ICON} isLoading={isLoading}>
      <Title isCentering={!isMobile}>
        {content.TITLE}
      </Title>

      {content.DESCRIPTION && (
        <Text className="mart-xs" isCentering={!isMobile} size="me">
          {templateParser(content.DESCRIPTION, { msisdn: makeUnbreakable(formatPhone(msisdn)) })}
        </Text>
      )}
    </ResultLayout>
  );
};

Push.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoints(Push);
