import classNames from 'classnames/bind';

import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const withLabel = (WrappedComponent) => ({ value, label, className, ...restProps }) => (
  <>
    <WrappedComponent
      {...restProps}
      label={label}
      value={value}
      className={cx('input', { withValue: value }, className)}
    />

    {label && (
      <Text size="s" className={cx('label')}>
        {label}
      </Text>
    )}
  </>
);
