// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LfjUmU {\n  position: relative;\n\n  display: block;\n}\n\n  .LfjUmU + .LfjUmU {\n    margin-top: 17px;\n  }\n\n.XHrteE:focus::-webkit-input-placeholder {\n  opacity: 0;\n}\n\n.XHrteE:focus::-moz-placeholder {\n  opacity: 0;\n}\n\n.XHrteE:focus:-ms-input-placeholder {\n  opacity: 0;\n}\n\n.XHrteE:focus::-ms-input-placeholder {\n  opacity: 0;\n}\n\n.XHrteE:focus::placeholder {\n  opacity: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/forms/components/Input/styles.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,cAAc;AAKhB;;EAHE;IACE,gBAAgB;EAClB;;AAGF;EACE,UAAU;AACZ;;AAFA;EACE,UAAU;AACZ;;AAFA;EACE,UAAU;AACZ;;AAFA;EACE,UAAU;AACZ;;AAFA;EACE,UAAU;AACZ","sourcesContent":[".component {\n  position: relative;\n\n  display: block;\n\n  & + & {\n    margin-top: 17px;\n  }\n}\n\n.input:focus::placeholder {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "LfjUmU",
	"input": "XHrteE"
};
export default ___CSS_LOADER_EXPORT___;
